const fetchProductionPredictionData = async (callSid) => {
  // Include dataType=production in the query string
  const apiEndpoint = `https://nv90iewjf2.execute-api.us-west-1.amazonaws.com/production/page?callSid=${callSid}&dataType=production`;
  try {
    const response = await fetch(apiEndpoint);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status} ${response.statusText}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching production prediction data:', error);
    throw new Error('Failed to fetch production prediction data');
  }
};

const fetchLocalPredictionData = async (callSid) => {
  // Include dataType=local in the query string
  const apiEndpoint = `https://nv90iewjf2.execute-api.us-west-1.amazonaws.com/production/page?callSid=${callSid}&dataType=local`;
  try {
    const response = await fetch(apiEndpoint);
    if (!response.ok) {
      console.error(`HTTP error! status: ${response.status} ${response.statusText}, url: ${apiEndpoint}`);
      // Log the entire response object for detailed diagnostics (consider doing this only in a development environment)
      console.error('Full response:', response);
      throw new Error(`HTTP error! status: ${response.status} ${response.statusText}`);
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching local prediction data:', error);
    // Optionally, log additional details about the error or request here
    throw new Error('Failed to fetch local prediction data');
  }
};

module.exports = { fetchProductionPredictionData, fetchLocalPredictionData };