import React, { useEffect, useRef } from 'react';
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugins/regions.esm.js';
import TimelinePlugin from 'wavesurfer.js/dist/plugins/timeline.esm.js';
import ZoomPlugin from 'wavesurfer.js/dist/plugins/zoom.esm.js';
import { fetchProductionPredictionData, fetchLocalPredictionData } from '../api/dynamoDB';

const Waveform = ({ audioUrl, callSid }) => {
    const productionWaveformRef = useRef(null);
    const localWaveformRef = useRef(null);
    const checkboxRef = useRef(null);
    const timelineRef = useRef(null);
    const minPxPerSecSpan = useRef(null);
    const wsProduction = useRef(null);
    const wsLocal = useRef(null);

    const labelColors = {
        recorded_message: 'rgba(255, 0, 0, 0.3)',
        music: 'rgba(0, 255, 0, 0.3)',
        silence: 'rgba(120, 0, 255, .3)',
        real_human: 'rgba(255, 255, 0, 1)'
    };

    const randomColor = () => '#' + Math.floor(Math.random() * 16777215).toString(16);

    const setupWaveform = (containerRef, dataFetchFunction, wsInstanceRef, identifier) => {
        const wsInstance = WaveSurfer.create({
            container: containerRef.current,
            height: 428,
            waveColor: '#000000',
            progressColor: '#000000',
            cursorColor: '#ddd5e9',
            cursorWidth: 1.5,
            minPxPerSec: 1,
            fillParent: true,
            url: audioUrl,
            scrollParent: false,
            interact: true,
            dragToSeek: false,
            hideScrollbar: false,
            audioRate: 1,
            autoScroll: true,
            autoCenter: true,
            sampleRate: 16000,
            borderWidth: 0,
            plugins: [
                TimelinePlugin.create({
                    container: timelineRef.current,
                }),
                ZoomPlugin.create({
                    scale: 0.5,
                    maxZoom: 100,
                }),
            ]
        });

        const wsRegions = wsInstance.registerPlugin(RegionsPlugin.create());

        wsInstance.on('ready', async () => {
          console.log(`[${identifier}] Waveform ready. Fetching data...`);
            try {
                const data = await dataFetchFunction(callSid);
                console.log(`[${identifier}] Data fetched:`, data);
                let predictions = data.predictions;
                if (!Array.isArray(predictions)) {
                    console.error('Predictions data is not an array:', predictions);
                    return;
                }

                predictions.sort((a, b) => a.timestamp - b.timestamp);
                console.log(`[${identifier}] Predictions sorted by timestamp.`);

                const mergedPredictions = [];
                predictions.forEach((prediction, index) => {
                    console.log(`[${identifier}] Processing prediction at index ${index}:`, prediction);
                    if (index > 0 && prediction.label === predictions[index - 1].label &&
                        prediction.timestamp === predictions[index - 1].timestamp + 1000) {
                        mergedPredictions[mergedPredictions.length - 1].end = (prediction.timestamp + 1000) / 1000;
                    } else {
                        mergedPredictions.push({
                            ...prediction,
                            start: prediction.timestamp / 1000,
                            end: (prediction.timestamp + 1000) / 1000,
                            start: identifier === 'Local' ? prediction.timestamp : prediction.timestamp / 1000,
                            end: identifier === 'Local' ? prediction.timestamp + 1 : (prediction.timestamp + 1000) / 1000,
                        });
                        console.log(`[${identifier}] Added new merged prediction at index ${index}.`);
                    }
                });

                console.log(`[${identifier}] Final merged predictions:`, mergedPredictions);
              
                mergedPredictions.forEach((prediction, index) => {
                  const region = wsRegions.addRegion({
                      start: prediction.start,
                      end: prediction.end,
                      color: labelColors[prediction.label] || randomColor(),
                      drag: false,
                      resize: true,
                      className: `region-${prediction.label}`,
                  });
                  console.log(`[${identifier}] Added region for prediction at index ${index}:`, region);
              });
          } catch (error) {
              console.error(`[${identifier}] Error fetching prediction data:`, error);
          }
      });

        // Preserve the region interaction logic
        let loop = true;
        checkboxRef.current.onclick = (e) => {
            loop = e.target.checked;
        };

        let activeRegion = null;
        wsRegions.on('region-in', (region) => {
            console.log('region-in', region);
            activeRegion = region;
        });
        wsRegions.on('region-out', (region) => {
            console.log('region-out', region);
            if (activeRegion === region) {
                if (loop) {
                    region.play();
                } else {
                    activeRegion = null;
                }
            }
        });
        wsRegions.on('region-clicked', (region, e) => {
            e.stopPropagation(); // Prevent triggering a click on the waveform
            activeRegion = region;
            region.play();
        });
        // Reset the active region when the user clicks anywhere in the waveform
        wsInstance.on('interaction', () => {
          activeRegion = null;
      });

      wsInstanceRef.current = wsInstance; // Assign the created instance to the ref
  };

  useEffect(() => {
      console.log('Starting setup for Production waveform...');
      // Pass 'production' as the dataType for production waveform setup
      setupWaveform(productionWaveformRef, () => fetchProductionPredictionData(callSid, 'production'), wsProduction, 'Production');

      console.log('Starting setup for Local waveform...');
      // Pass 'local' as the dataType for local waveform setup
      setupWaveform(localWaveformRef, () => fetchLocalPredictionData(callSid, 'local'), wsLocal, 'Local');

      const handleKeyDown = (e) => {
          if (e.code === 'Space') {
              e.preventDefault();
              const isPlayingProduction = wsProduction.current.isPlaying();
              const isPlayingLocal = wsLocal.current.isPlaying();
              if (isPlayingProduction || isPlayingLocal) {
                  wsProduction.current.pause();
                  wsLocal.current.pause();
              } else {
                  wsProduction.current.play();
                  wsLocal.current.play();
              }
          }
      };

      window.addEventListener('keydown', handleKeyDown);

      const handleResize = () => {
          [wsProduction.current, wsLocal.current].forEach(ws => {
              if (ws) {
                  ws.drawer.containerWidth = ws.drawer.container.clientWidth;
                  ws.drawBuffer();
                  if (ws.timeline) {
                      ws.timeline.render();
                  }
              }
          });
      };

      window.addEventListener('resize', handleResize);

      return () => {
          wsProduction.current.destroy();
          wsLocal.current.destroy();
          window.removeEventListener('keydown', handleKeyDown);
          window.removeEventListener('resize', handleResize);
      };
  }, [audioUrl, callSid]);

  const handlePlayPause = () => {
      const isPlayingProduction = wsProduction.current.isPlaying();
      const isPlayingLocal = wsLocal.current.isPlaying();
      if (isPlayingProduction || isPlayingLocal) {
          wsProduction.current.pause();
          wsLocal.current.pause();
      } else {
          wsProduction.current.play();
          wsLocal.current.play();
      }
  };

  const handleSkipForward = () => {
      wsProduction.current.skip(5);
      wsLocal.current.skip(5);
  };

  const handleSkipBackward = () => {
      wsProduction.current.skip(-5);
      wsLocal.current.skip(-5);
  };

  return (
      <div>
          <div className="prediction-labels">
              {Object.entries(labelColors).map(([label, color]) => (
                  <div key={label} className="prediction-label">
                      <span className="label-color" style={{ backgroundColor: color }}></span>
                      <span className="label-text">{label.replace('_', ' ')}</span>
                  </div>
              ))}
          </div>
          <div className="waveform" ref={productionWaveformRef}></div>
          <div ref={timelineRef}></div>
          <div className="waveform" ref={localWaveformRef}></div>
          <div>
              minPxPerSec: <span ref={minPxPerSecSpan}>100</span> px/s
          </div>
          <label>
              <input type="checkbox" ref={checkboxRef} defaultChecked={true} /> Loop regions
              <button onClick={handlePlayPause}>Play/Pause</button>
              <button onClick={handleSkipForward}>Forward 5s</button>
              <button onClick={handleSkipBackward}>Backward 5s</button>
          </label>
      </div>
  );
};

export default Waveform;