// frontend/src/App.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Waveform from './components/Waveform';
import { fetchProductionPredictionData, fetchLocalPredictionData } from './api/dynamoDB';
import './App.css';

const App = () => {
    const [callSid, setCallSid] = useState(''); // State for storing user input for callSid
    const [inputCallSid, setInputCallSid] = useState(''); // State for handling input field
    const [audioUrl, setAudioUrl] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        if (!callSid) return; // Don't fetch if callSid is not set

        const fetchAudioUrl = async () => {
            setLoading(true);
            setError('');
            try {
                const response = await axios.get(`https://nv90iewjf2.execute-api.us-west-1.amazonaws.com/production/page?callSid=${callSid}`);
                setAudioUrl(response.data.url);
            } catch (error) {
                console.error('Error fetching audio file:', error);
                setError('Failed to load audio file.');
            } finally {
                setLoading(false);
            }
        };

        fetchAudioUrl();
    }, [callSid]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setCallSid(inputCallSid); // Update callSid to trigger useEffect for fetching audio URL
    
        try {
            const productionData = await fetchProductionPredictionData(inputCallSid);
            console.log('Production Data:', productionData);
            const localData = await fetchLocalPredictionData(inputCallSid);
            console.log('Local Data:', localData);
            // Handle the fetched prediction data as needed
        } catch (error) {
            console.error('Error fetching prediction data:', error);
            setError('Failed to fetch prediction data.'); // Optionally update state to reflect the error
        }
    };

    return (
        <div className="App">
            <form onSubmit={handleSubmit}>
                <label htmlFor="callSid">Enter CallSid:</label>
                <input
                    id="callSid"
                    type="text"
                    value={inputCallSid}
                    onChange={(e) => setInputCallSid(e.target.value)}
                    placeholder="Enter CallSid"
                />
                <button type="submit">Load Audio</button>
            </form>
            {loading && <p>Loading...</p>}
            {error && <p>Error: {error}</p>}
            {audioUrl && <Waveform audioUrl={audioUrl} callSid={callSid} />}
        </div>
    );
};

export default App;
